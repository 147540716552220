angular.module("app")
    .directive("cEditFieldPanel", function ($rootScope, _v, _mapFieldService, _view, gettextCatalog) {
        return {
            restrict: "E",
            templateUrl: "t-c-edit-field-panel",
            scope: {
                  fieldId: '@fieldId'
            },
            link: function ($scope) {
                $scope.opened = false;
                $scope.form = 'field';
                $scope.field = _view.getFieldById($scope.fieldId);
                $scope.field.area = _mapFieldService.getArea($scope.field);
                $scope.farms = _.sortBy(_view.getFarms(), 'name');

                var initializeFarmSelections = function() {
                    $scope.field.farmSelect = {selected: $scope.field.farm ? _view.getFarmById($scope.field.farm.id) : undefined};
                };

                $scope.open = function() {
                    initializeFarmSelections();
                    $scope.opened = true;
                };

                $scope.save = function() {
                    $scope.field.name = $scope.field.name.trim();
                    if ($scope.field.name) {
                        $scope.opened = false;
                    }
                };

                $scope.deleteField = function(fieldId) {
                    _mapFieldService.deleteField($scope.field);
                    _view.deleteField($scope.field);
                    _v.change({set: {field: undefined, pfield: undefined, farm: $scope.field.farm ? $scope.field.farm.id : undefined}});
                };

                $scope.onFarmSelect = function(item, model) {
                    delete $scope.field.farm;

                    if ($scope.field.farmSelect.selected) {
                        $scope.field.farm = {id: $scope.field.farmSelect.selected.id};
                    }
                    _view.buildNavigation();
                    _v.change({set: {pfield: undefined, farm: $scope.field.farm ? $scope.field.farm.id : undefined}});
                };

                $scope.onFieldsFarmsChange = function() {
                    _view.buildNavigation();
                };

                $scope.$on("mapService:fieldChanged", function(event, data) {
                    if ($scope.fieldId.toString() == data[0].fieldId.toString()) {
                        $rootScope.$safeApply(function() {
                            $scope.field.area = _mapFieldService.getArea($scope.field);
                        });
                    }
                });

                $scope.addFarm = function() {
                    $scope.form = 'addFarm';
                    $scope.editFarm = {name: ''};
                };

                $scope.saveFarm = function() {
                    $scope.editFarm.name = $scope.editFarm.name.trim();
                    if ($scope.editFarm.name) {
                        var newFarm = {id: _.uniqueId("farm"), name: $scope.editFarm.name, newFarm: true};
                        $scope.field.farm = {id: newFarm.id};
                        $scope.field.farmSelect.selected = newFarm;
                        _view.addFarm(newFarm);
                        $scope.farms = _.sortBy(_view.getFarms(), 'name');
                        _v.change({set: {pfield: undefined, farm: $scope.field.farm.id}});
                        $scope.form = 'field';
                    }
                };

                $scope.cancelSaveFarm = function() {
                    $scope.form = 'field';
                };

                $scope.editFarmOpen = function() {
                    $scope.form = 'editFarm';
                    $scope.editFarm = {name: $scope.field.farmSelect.selected.name};
                };

                $scope.updateFarm = function() {
                    $scope.editFarm.name = $scope.editFarm.name.trim();
                    if ($scope.editFarm.name) {
                        _view.getFarmById($scope.field.farmSelect.selected.id).name = $scope.editFarm.name;
                        _view.buildNavigation();
                        $scope.form = 'field';
                    }
                };

                $scope.deleteFarm = function() {
                    _view.deleteFarm($scope.field.farmSelect.selected.id);
                    delete $scope.field.farm;
                    $scope.field.farmSelect.selected = undefined;
                    $scope.farms = _.sortBy(_view.getFarms(), 'name');
                    _v.change({set: {farm: undefined}});
                    $scope.form = 'field';
                };
            }
        }
    });